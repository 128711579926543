const ChimeMessaging = require('aws-sdk/clients/chimesdkmessaging')
// import config from '../Config';

import { Auth } from 'aws-amplify'




async function getCredentials() {
   return await Auth.currentCredentials()
 }

async function getCurrentUser() {
  return await Auth.currentAuthenticatedUser()
}

export const createMemberArn = process.env.VUE_APP_CHIME_APP_INSTANCE_ARN + '/user/Admin'
const appInstanceUserArnHeader = 'x-amz-chime-bearer'
const region = 'us-east-1'
let chimeMessaging = null

// Setup Chime Messaging Client lazily
async function chimeMessagingClient () {
  const credentials = await getCredentials()
  const userDetails = await getCurrentUser()
  const userId = userDetails.attributes.sub
  if (chimeMessaging == null) {
    const options = {
      region: 'us-east-1',
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken
    }
    chimeMessaging = new ChimeMessaging(options)
  }
  return chimeMessaging
}

async function getMessagingSessionEndpoint () {
  const request = (await chimeMessagingClient()).getMessagingSessionEndpoint()
  const response = await request.promise()
  return response
}

async function sendChannelMessage (messageContent, ChannelArn) {
  const userDetails = await getCurrentUser()
  const userId = userDetails.attributes.sub
  const chimeBearerArn = process.env.VUE_APP_CHIME_APP_INSTANCE_ARN + '/user/' + userId

  const params = {
    ChimeBearer: chimeBearerArn,
    ChannelArn: ChannelArn,
    Content: messageContent,
    Persistence: 'PERSISTENT',
    Type: 'STANDARD'
  }

  console.log({params: params})
  const request = (await chimeMessagingClient()).sendChannelMessage(params)
  const response = await request.promise()
  const sentMessage = {
    response: response,
    CreatedTimestamp: new Date(),
    Sender: { Arn: chimeBearerArn, Name: 'Admin' }
  }
  return sentMessage
}

async function listChannelMessages (ChannelArn, nextToken = null) {
  console.log("list channel messages function")
  const chimeBearerArn = createMemberArn

  const params = {
    ChannelArn: ChannelArn,
    NextToken: nextToken,
    ChimeBearer: chimeBearerArn
  }
  const request = (await chimeMessagingClient()).listChannelMessages(params)
  const response = await request.promise()
  const messageList = response.ChannelMessages
  messageList.sort(function (a, b) {
    // eslint-disable-next-line no-nested-ternary
    return a.CreatedTimestamp < b.CreatedTimestamp
      ? -1
      : a.CreatedTimestamp > b.CreatedTimestamp
        ? 1
        : 0
  })

  const messages = []
  for (let i = 0; i < messageList.length; i++) {
    const message = messageList[i]
    messages.push(message)
  }
  return { Messages: messages, NextToken: response.NextToken }
}

async function listChannelMemberships(channelArn) {
  const chimeBearerArn = createMemberArn
  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn
  };

  const request = (await chimeMessagingClient()).listChannelMemberships(params);
  const response = await request.promise();
  return response.ChannelMemberships;
}

export {
  sendChannelMessage,
  listChannelMessages,
  getMessagingSessionEndpoint,
  getCredentials,
  listChannelMemberships
}
