<template>
    <div>
        <ViewData />
        <b-button id="printPdfButton" v-on:click="createPDF">Download file as PDF</b-button>
        <ChatWindow v-if='chimeChannel.length > 0' />
        <b-modal class="text-center" id="modal-1" ok-only hide-header hide-footer>
          <h1>Generating PDF</h1>
        </b-modal>
        
    </div>

</template>

<script>
import StaticTable from '../../components/factory/StaticTable.vue'
import ViewData from '../../components/factory/ViewData.vue'
import Images from '../../components/factory/Images.vue'
import ChatWindow from '../../components/ChatWindow.vue'
const axios = require('axios')

export default {
  components: {
    StaticTable,
    ViewData,
    Images,
    ChatWindow
  },
  methods: {
    getS3SignedUrl() {
      let techpackData = this.$store.getters.getSelectedTechpackData[0]
      let garmentName = techpackData.name
      let designerId = techpackData.designer_id
      const pdfName = garmentName.replace(/\s+/g, '-').toLowerCase() + '-' + designerId;

      console.log({pdfName: pdfName})
      const rootEndpoint = process.env.VUE_APP_TECHPACK_EXPORT_API_ENDPOINT
      console.log({rootEndpoint: rootEndpoint})
      const endpoint = rootEndpoint + 'gets3SignedUrl/' + pdfName
      console.log(endpoint)
      axios.get(endpoint)
        .then(response => {
          console.log(response.data)
          let link = document.createElement('a');
          link.setAttribute('target', '_blank');
          console.log(response.data.objectURL)
          link.href = response.data.objectURL
          link.download = pdfName + '.pdf';
          link.click();
          link.remove();
          // this.$bvModal.hide("modal-1")	
        }).catch(error => {
          console.log(error)
        })

    },

    async createPDF() {
      // this.$bvModal.show("modal-1")	
      let techpackData = this.$store.getters.getSelectedTechpackData[0]
      let garmentName = techpackData.name
      const lambdaEndpoint = process.env.VUE_APP_TECHPACK_EXPORT_API_ENDPOINT
      console.log({lambdaEndpoint: lambdaEndpoint})
      axios.post(lambdaEndpoint, techpackData, {
      }).then((res) => {
        console.log({lambdaResult: res})
        this.getS3SignedUrl().then(() => {
          console.log('downloaded')
        })
        }).catch(function (error) {
          if (error.response) {
            console.log(error.response.data)
            throw new Error(JSON.stringify(error.response.data))
          }
        })
      
    },

  
  },
  computed: {
    listOfImages () {
      return this.$store.state.selectedGarment.fileNames
    },
    chimeChannel () {
      return this.$store.state.chimeChannelArn
    },
  }
}
</script>

<style>
#printPdfButton {
    margin-bottom: 20px;
    margin-left: 150px;
}
</style>
