<template>
    <div>
        <b-navbar type="dark" variant="dark">
            <b-navbar-brand>
                Tailr
            </b-navbar-brand>
            <b-nav-text v-if='isLoggedIn' class='pr-3'>{{printUserType}}</b-nav-text>
            <b-button v-if='isLoggedIn' @click="logout">Logout</b-button>

        </b-navbar>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'Navbar',
  props: ['userType'],
  methods: {
    async logout () {
      try {
        await Auth.signOut()
        this.$store.commit('logOut')
        alert('Signed out')
        this.$router.push('/login')
      } catch (error) {
        alert(error.message)
      }
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.loggedIn
    },
    printUserType () {
      if (this.userType === 'factoryusergroup') {
        return 'factory'
      } else {
        return 'designer'
      }
    }
  }
}
</script>
